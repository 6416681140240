<template>
  <b10-base>
    <b10-date-time-picker
      v-model="form.finicio"
      :disabled="porCronometro"
      title="Inicio"
      :rules="formRules.finicio"
    />
    <b10-time-picker
      v-model="form.hora_fin"
      :disabled="porCronometro"
      title="Hora de fin"
      :rules="formRules.hora_fin"
    />
    <v-text-field
      v-model.number="form.kilometros"
      label="Kilómetros"
      clearable
      type="number"
      @input="changeKilometros"
    />
    <v-checkbox
      v-model="form.es_desplazamiento"
      label="Es desplazamiento"
      @change="changeEsDesplazamiento"
    />
    <v-autocomplete
      v-model="form.idvehiculo"
      :disabled="!form.es_desplazamiento"
      label="Vehículo"
      :items="formattedVehiculos"
      item-value="idvehiculo"
      item-text="title"
      clearable
    />
    <v-autocomplete
      v-model="form.idttiempo_trabajado"
      label="Tipo de tarea"
      :items="tipoTiempoTrabajado"
      item-value="idttiempo_trabajado"
      item-text="descripcion"
      clearable
      @change="changeTtiempoTrabajado"
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <v-text-field
      v-model.number="form.latitud"
      label="Latitud"
      disabled
    />
    <v-text-field
      v-model.number="form.longitud"
      label="Longitud"
      disabled
    />
    <v-btn
      v-show="form.latitud && form.longitud"
      block
      color="secondary"
      dark
      @click.stop="clickIrMapa(form.latitud, form.longitud)"
    >
      <v-icon
        left
      >
        {{ $vuetify.icons.values.mapLocation }}
      </v-icon> Localización
    </v-btn>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import _ from '@/utils/lodash'
import Data from './TiempoFormData'
import { PARAMETRO } from '@/utils/consts'
import { getLatLongURL } from '@/utils/maps'
import { currentDateTime, parseHHmm, toDate, addSeconds, currentTime, currentDate } from '@/utils/date'

export default {
  mixins: [formMixin],
  props: {
    idempleado: {
      type: Number,
      required: true,
    },
    hasPermIntroducirTiemposMasAllaFechaActual: {
      type: Boolean,
      default: false,
    },
    geolocalizacion: {
      type: Object,
      default: () => {},
    },
    porCronometro: {
      type: Boolean,
      default: false,
    },
    finicio: {
      type: Date,
      default: null,
    },
    esDesplazamiento: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        finicio: null,
        hora_fin: null,
        kilometros: 0,
        es_desplazamiento: false,
        observaciones: null,
        latitud: null,
        longitud: null,
        idvehiculo: null,
        idttiempo_trabajado: null,
        coste_hora: 0,
        cronometro: this.porCronometro,
      },
      formRules: {
        finicio: [
          v => !!v || 'Campo requerido'
        ],
        hora_fin: [
          v => this.horasFinValida(v)
        ],
      },
      vehiculos: [],
      tipoTiempoTrabajado: [],
    }
  },
  computed: {
    formattedVehiculos () {
      return _.map(this.vehiculos, (item) => {
        item.title = `${item.descripcion} (${item.matricula})`
        return item
      })
    },
  },
  watch: {
    geolocalizacion: {
      handler: function (newValue) {
        if (newValue) {
          this.updateGeolocalizacion(newValue)
        }
      },
      deep: true,
    },
  },
  async created () {
    const resp = await Data.selectLookups(this, this.idempleado)
    this.vehiculos = resp.data.selectVehiculo.result.dataset
    // Si el empleado no tiene tipos de tarea asignada utilizo todos y lo que venga por parámetro
    const tipoTiempoTrabajadoEmpleado = resp.data.empleadoTtiempoTrabajadoSelect.result.dataset
    let idttiempoTrabajado = null
    if (tipoTiempoTrabajadoEmpleado.length > 0) {
      this.tipoTiempoTrabajado = tipoTiempoTrabajadoEmpleado
      const tiempoTrabajadoPorDefecto = _.find(tipoTiempoTrabajadoEmpleado, { 'por_defecto': true })
      idttiempoTrabajado = tiempoTrabajadoPorDefecto ? tiempoTrabajadoPorDefecto.idttiempo_trabajado : null
    } else {
      this.tipoTiempoTrabajado = resp.data.selectTtiempoTrabajado.result.dataset
      idttiempoTrabajado = await this.$online.parametro.valorParametro(PARAMETRO.parametros.IDTTIEMPO_TRA_DEFECTO)
    }
    if (idttiempoTrabajado) {
      this.$set(this.form, 'idttiempo_trabajado', parseInt(idttiempoTrabajado))
      // forzar evento:
      this.changeTtiempoTrabajado(idttiempoTrabajado)
    }
    if (this.finicio && this.porCronometro) {
      this.$set(this.form, 'finicio', this.finicio)
      this.$set(this.form, 'hora_fin', currentTime())
    }
    if (this.esDesplazamiento) {
      this.$set(this.form, 'es_desplazamiento', this.esDesplazamiento)
    }
    this.changeEsDesplazamiento(this.esDesplazamiento)
    this.updateGeolocalizacion(this.geolocalizacion)
    await this.loadForm()
  },
  methods: {
    updateGeolocalizacion (newValue) {
      this.$set(this.form, 'latitud', newValue?.lat)
      this.$set(this.form, 'longitud', newValue?.lng)
    },
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    async changeKilometros (value) {
      if (value > 0) {
        this.$set(this.form, 'es_desplazamiento', true)
      } else {
        this.$set(this.form, 'es_desplazamiento', false)
      }
      this.changeEsDesplazamiento(this.form.es_desplazamiento)
    },
    horasFinValida (value) {
      if (!value) {
        return 'Campo requerido'
      }
      if (!this.form.finicio) {
        return true
      }
      const inicioDate = toDate(this.form.finicio)
      const fin = addSeconds(parseHHmm(this.form.hora_fin, inicioDate), 59)
      const currentDateTimePlus59 = addSeconds(currentDateTime(), 59)

      if (inicioDate < currentDate() || this.form.finicio <= fin) {
        if (fin > currentDateTimePlus59 && !this.hasPermIntroducirTiemposMasAllaFechaActual) {
          return 'El tiempo trabajado va más allá del momento actual'
        }
        return true
      }
      return 'La hora de fin debe ser posterior a la de inicio'
    },
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
    changeEsDesplazamiento (value) {
      if (value) {
        const vehiculoEmpleado = _.find(this.vehiculos, { idempleado: this.idempleado })
        if (vehiculoEmpleado) {
          this.$set(this.form, 'idvehiculo', vehiculoEmpleado.idvehiculo)
        }
      } else {
        this.$set(this.form, 'idvehiculo', null)
      }
    },
    changeTtiempoTrabajado (value) {
      const tiempoTrabajadoSelecionado = _.find(this.tipoTiempoTrabajado, { 'idttiempo_trabajado': parseInt(value) })
      let costeHora = 0
      if ('coste_hora' in tiempoTrabajadoSelecionado) {
        costeHora = tiempoTrabajadoSelecionado.coste_hora
      }
      this.$set(this.form, 'coste_hora', costeHora)
    },
  }
}
</script>
