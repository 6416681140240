import { PARAMETRO } from '@/utils/consts'

export default {
  async insert (Vue, formData, idempleado, ffin) {
    if (formData.es_desplazamiento) {
      const costeKilometro = await Vue.$online.parametro.valorParametro(PARAMETRO.parametros.COSTE_KILOMETRO)
      if (costeKilometro) {
        formData.coste_kilometro = parseFloat(costeKilometro.replace(',', '.'))
      }
    } else {
      formData.coste_kilometro = 0
    }
    // controlar que los kilometros no estén a null
    formData.kilometros = formData.kilometros == null || !(formData.kilometros) ? 0 : formData.kilometros
    // controlar los imputaAlProyecto, si existe proyecto imputa
    let imputaAlProyecto = formData.idproyecto_contable == null ? 0 : 1
    await Vue.$api.call(
      'tiempoTrabajado.insert',
      {
        values: {
          // por defecto la mano de obra no va facturable
          manodeobra_facturable: false,
          kilometros_facturables: false,
          imputa_al_proyecto_mo: imputaAlProyecto,
          imputa_al_proyecto_desp: imputaAlProyecto,
          imputa_al_proyecto_otros: imputaAlProyecto,
          cronometro: false,
          idempleado: idempleado,
          finicio: formData.finicio,
          ffin: ffin,
          kilometros: formData.kilometros,
          observaciones: formData.observaciones,
          es_desplazamiento: formData.es_desplazamiento,
          latitud_inicio: formData.latitud,
          longitud_inicio: formData.longitud,
          latitud_fin: formData.latitud,
          longitud_fin: formData.longitud,
          idvehiculo: formData.idvehiculo,
          coste_kilometro: formData.coste_kilometro,
          idttiempo_trabajado: formData.idttiempo_trabajado,
          coste_hora: formData.coste_hora,
          cronometro: formData.cronometro,
        },
      },
    )
  },
}



